export const GET_LANG = "GET_LANG";

export const HANDLE_FORM = "HANDLE_FORM";

export const GET_SAC_EKIMI = "GET_SAC_EKIMI";

export const GET_ESTETIK_PLASTIK_CER = "GET_ESTETIK_PLASTIK_CER";

export const GET_OBEZITE_METABOLIK_CER = "GET_OBEZITE_METABOLIK_CER";

export const GET_AGIZ_DIS = "GET_AGIZ_DIS";

export const GET_KBB = "GET_KBB";

export const GET_GOZ_SAGLIGI = "GET_GOZ_SAGLIGI";

export const GET_GUZELLIK_MERKEZI = "GET_GUZELLIK_MERKEZI";

export const GET_HAKKIMIZDA = "GET_HAKKIMIZDA";

export const GET_GALERI = "GET_GALERI";

export const GET_ANASAYFA = "GET_ANASAYFA";

export const GET_OTHERS = "GET_OTHERS";

export const GET_FORMNAMES = "GET_FORMNAMES";

export const GET_ILETISIM = "GET_ILETISIM";

export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
