import React from 'react';

function Brand() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='193.823'
      height='77.591'
      viewBox='0 0 193.823 77.591'
    >
      <g
        id='Group_109'
        data-name='Group 109'
        transform='translate(-513.23 -399.331)'
      >
        <g
          id='Group_109-2'
          data-name='Group 109'
          transform='translate(540.248 451.893)'
        >
          <path
            id='Path_50'
            data-name='Path 50'
            d='M554.067,471.16h6.3v6.167a9.177,9.177,0,0,1-6.121,2.407,8.151,8.151,0,0,1,0-16.3,9.063,9.063,0,0,1,6.121,2.407l-1.215,1.238a7.22,7.22,0,0,0-4.906-1.995,6.5,6.5,0,0,0,0,13,7.575,7.575,0,0,0,4.333-1.468v-3.76h-4.516Z'
            transform='translate(-546.18 -463.433)'
          />
          <path
            id='Path_51'
            data-name='Path 51'
            d='M578.683,477.823v1.7H568.3v-15.75h1.788v14.054Z'
            transform='translate(-550.161 -463.493)'
          />
          <path
            id='Path_52'
            data-name='Path 52'
            d='M581.409,471.572a8.06,8.06,0,1,1,8.07,8.162A8.218,8.218,0,0,1,581.409,471.572Zm14.307,0a6.253,6.253,0,1,0-6.237,6.511A6.449,6.449,0,0,0,595.716,471.572Z'
            transform='translate(-552.522 -463.433)'
          />
          <path
            id='Path_53'
            data-name='Path 53'
            d='M605.426,463.769h7.222c2.545,0,4.334,1.628,4.334,3.92a3.743,3.743,0,0,1-2.247,3.462,4.587,4.587,0,0,1,2.865,4.058c0,2.521-1.948,4.31-4.676,4.31h-7.5Zm6.764,6.671c1.742,0,2.981-1.055,2.981-2.521s-1.238-2.522-2.981-2.522h-4.976v5.044Zm.275,7.451c1.949,0,3.325-1.215,3.325-2.912s-1.376-2.912-3.325-2.912h-5.25v5.823Z'
            transform='translate(-556.846 -463.493)'
          />
          <path
            id='Path_54'
            data-name='Path 54'
            d='M634.793,475.943h-8.78l-1.583,3.576h-1.948l6.947-15.75h1.948l6.947,15.75h-1.949Zm-.734-1.7-3.645-8.277-3.669,8.277Z'
            transform='translate(-559.916 -463.493)'
          />
          <path
            id='Path_55'
            data-name='Path 55'
            d='M655.822,477.823v1.7H645.436v-15.75h1.788v14.054Z'
            transform='translate(-564.048 -463.493)'
          />
        </g>
        <g
          id='Group_110'
          data-name='Group 110'
          transform='translate(513.23 421.252)'
        >
          <path
            id='Path_56'
            data-name='Path 56'
            d='M534.256,426.065v25.749h-2.923V440.083H516.153v11.731H513.23V426.065h2.923v11.244h15.179V426.065Z'
            transform='translate(-513.23 -426.065)'
          />
          <path
            id='Path_57'
            data-name='Path 57'
            d='M561.273,435.112v-2.923H546.18v20.727h15.092v-2.923H549.248v-6.169h10.834V440.9H549.248v-5.791Z'
            transform='translate(-519.162 -427.167)'
          />
          <path
            id='Path_58'
            data-name='Path 58'
            d='M579.665,432.7l-.061-.139h-2.774l-9.007,20.422H570.8l2-4.531h10.82l2,4.531h2.98Zm2.825,13.134h-8.546l4.286-9.672Z'
            transform='translate(-523.058 -427.234)'
          />
          <path
            id='Path_59'
            data-name='Path 59'
            d='M599.791,450.365v-17.8h-2.734v20.423h13.626v-2.618Z'
            transform='translate(-528.321 -427.234)'
          />
          <path
            id='Path_60'
            data-name='Path 60'
            d='M613.076,432.561v2.618h6.709v17.8h2.734v-17.8h6.709v-2.618Z'
            transform='translate(-531.204 -427.234)'
          />
          <path
            id='Path_61'
            data-name='Path 61'
            d='M651.688,432.561v8.714H640.393v-8.714h-2.734v20.423h2.734v-9.091h11.295v9.091h2.734V432.561Z'
            transform='translate(-535.63 -427.234)'
          />
          <path
            id='Path_62'
            data-name='Path 62'
            d='M683.268,445.967H668.913l-2.585,5.847h-3.186L674.5,426.065h3.186l11.357,25.749h-3.186Zm-1.2-2.773-5.959-13.53-6,13.53Z'
            transform='translate(-540.217 -426.065)'
          />
        </g>
        <path
          id='Path_63'
          data-name='Path 63'
          d='M671.864,466.577'
          transform='translate(-28.557 -12.106)'
        />
        <g
          id='Group_111'
          data-name='Group 111'
          transform='translate(614.881 399.331)'
        >
          <path
            id='Path_64'
            data-name='Path 64'
            d='M703.828,461.13l-2.309-2.308,26.556-26.58a13.281,13.281,0,1,0-18.78-18.784l-2.31-2.309a16.546,16.546,0,1,1,23.4,23.4Z'
            transform='translate(-648.777 -400.586)'
          />
          <path
            id='Path_65'
            data-name='Path 65'
            d='M710.495,449.146l-2.31-2.307,17.239-17.251a7.836,7.836,0,1,0-11.08-11.085l-2.309-2.309a11.1,11.1,0,1,1,15.7,15.7Z'
            transform='translate(-649.977 -401.781)'
          />
          <path
            id='Path_66'
            data-name='Path 66'
            d='M722.864,405.837a22.3,22.3,0,0,0-31.5.016l-5.382,5.38a3.789,3.789,0,0,1-2.943,1.109,3.994,3.994,0,0,1-2.543-1.2l-5.286-5.286a22.24,22.24,0,0,0-36.9,8.8H638.3c-.057.175-.106.351-.157.526s-.1.332-.148.5c-.1.385-.2.772-.281,1.161-.019.087-.045.171-.063.259h.011a22.344,22.344,0,0,0-.435,5.559h3.29a19.2,19.2,0,0,1,.529-5.693c.089-.357.186-.712.3-1.062l.024-.081a18.978,18.978,0,0,1,31.541-7.663l5.229,5.228a7.362,7.362,0,0,0,4.966,2.223,7.031,7.031,0,0,0,5.195-2.071l5.382-5.38a19,19,0,1,1,26.866,26.867L683.289,472.3,663.453,452.34h-4.617l24.453,24.582,39.56-39.586A22.3,22.3,0,0,0,722.864,405.837Z'
            transform='translate(-637.198 -399.331)'
          />
        </g>
      </g>
    </svg>
  );
}

export default Brand;
